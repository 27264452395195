import theme from '@pineapple/ui/cjs/theme';
import SplashScreen from '@pineapple/ui/cjs/SplashScreen';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React from 'react';

const Loading = ({ name }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <SplashScreen name={name} />
    </ThemeProvider>
  </StyledEngineProvider>
);

export default Loading;
