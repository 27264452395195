import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';

import initSentry from './sentry';
import Loading from './Loading';

const App = lazy(() => import('./app/app'));

const title = 'BO Finance Console';

fetch('./config.json')
  .then((response) => response.json())
  .then((config) => {
    window.env = config;
    initSentry();
  })
  .catch(() => {
    window.env = {};
  })
  .finally(() => {
    ReactDOM.render(
      <React.StrictMode>
        <Suspense fallback={<Loading name={title} />}>
          <App title={title} />
        </Suspense>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  });
